/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/layout';
import { getTranslate } from 'react-localize-redux';
import { hideAllNotifications, showNotification, SEVERITY } from '../state/notifications';
import * as yup from 'yup';
import FormField from '../components/FormField';
import { Form } from 'formik';
import { Container } from '../components';
import { withFormik } from 'formik';
import RecaptchaButton from '../components/RecaptchaButton';
import * as api from '../utils/api';
import { handleFormValidationError } from '../utils/formError';
import { locNavigate } from '../state/session';
import Spinner from '../components/Spinner';
import * as queryString from 'query-string';
import testLogger from '../components/test-logger';
import { deviceDetect } from 'react-device-detect';
import * as analytics from '../utils/analytics';

const strikeRefundFormSchema = translate =>
  yup.object().shape({
    name: yup.string().required(translate('strikeRefundForm.message.requiredField')),
    email: yup
      .string()
      .required(translate('strikeRefundForm.message.requiredField'))
      .email(translate('strikeRefundForm.message.invalidEmail')),
    phoneNumber: yup.string(),
    bankAccount: yup.string().required(translate('strikeRefundForm.message.requiredField')),
    orderNumber: yup.string(),
    ticketNumber: yup.string().matches(/^F[0-9]{18}$/, {
      message: translate('strikeRefundForm.invalidTicketNumber'),
      excludeEmptyString: true,
      onFocusOut: true,
    }),
    ticketName: yup.string().required(translate('strikeRefundForm.message.requiredField')),
    timeFrame: yup.string().required(translate('strikeRefundForm.message.requiredField')),
    timeDays: yup.string().required(translate('strikeRefundForm.message.requiredField')),
    recaptcha: yup.string().required(),
  });

const StrikeRefundForm = ({
  translate,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  errors,
  nocaptcha,
  values,
  touched,
}) => {
  const hasTouched = Object.keys(touched).includes('orderNumber') || Object.keys(touched).includes('ticketNumber');
  const hasValues = values?.orderNumber || values?.ticketNumber;

  return (
    <Form
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <FormField name="name" label={translate('strikeRefundForm.name')} />
      <FormField name="email" label={translate('strikeRefundForm.email')} />
      <FormField name="phoneNumber" label={translate('strikeRefundForm.phoneNumber')} />
      <FormField name="bankAccount" label={translate('strikeRefundForm.bankAccount')} />
      <Styled.h2>{translate('strikeRefundForm.subtitle')}</Styled.h2>
      <p>{translate('strikeRefundForm.subtext1')}</p>
      {hasTouched && !hasValues ? (
        <p sx={{ color: 'danger', fontSize: 1 }}>{translate('strikeRefundForm.ticketOrOrderNumber')}</p>
      ) : null}
      <FormField name="ticketNumber" label={translate('strikeRefundForm.ticketNumber')} />
      <FormField name="orderNumber" label={translate('strikeRefundForm.orderNumber')} />
      <FormField name="ticketName" label={translate('strikeRefundForm.ticketName')} />
      <FormField name="timeFrame" label={translate('strikeRefundForm.timeFrame')} />
      <FormField name="timeDays" label={translate('strikeRefundForm.timeDays')} />
      <RecaptchaButton
        buttonText="strikeRefundForm.send"
        translate={translate}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        nocaptcha={nocaptcha}
        errors={errors}
        disabled={!hasValues}
      />
    </Form>
  );
};

const StrikeRefundFormik = withFormik({
  mapPropsToValues: ({ vo }) =>
    Object.assign({
      name: vo.name || '',
      email: vo.email || '',
      phoneNumber: vo.phoneNumber || '',
      bankAccount: vo.bankAccount || '',
      ticketNumber: vo.ticketNumber || 'F246300020',
      orderNumber: vo.orderNumber || '',
      ticketName: vo.ticketName || '',
      timeFrame: vo.timeFrame || '',
      timeDays: vo.timeDays || '',
      recaptcha: vo.recaptcha || '',
    }),
  validationSchema: ({ translate }) => {
    return strikeRefundFormSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'StrikeRefundInputForm',
})(StrikeRefundForm);

const StrikeRefundFormPage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const [vo, setVO] = useState({});
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { nocaptcha } = queryParams;

  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      setShowSpinner(true);
      dispatch(hideAllNotifications());
      try {
        await api.sendStrikeRefundForm({ ...vo, locale });
        dispatch(
          locNavigate('/thank-you', '', {
            state: {
              message: translate('strikeRefundForm.message.success'),
              noDefault: true,
            },
          })
        );
        setShowSpinner(false);
        setSubmitting(false);
      } catch (error) {
        testLogger.log('sendTicketCancellationForm:FAILED ' + error + ', ' + JSON.stringify(deviceDetect()));
        setShowSpinner(false);
        setSubmitting(false);
        if (handleFormValidationError(error, dispatch, translate)) {
          return;
        }
        console.error(error);
        dispatch(showNotification('strikeRefundForm.message.failed', SEVERITY.ERROR));
      }
      setVO({});
      window && window.scrollTo(0, 0);
    },
    [dispatch, locale, translate]
  );
  const formProps = { vo, onSubmit, translate, nocaptcha };

  return (
    <Layout title={translate('strikeRefundForm.title')} paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      <Container
        sx={{
          maxWidth: 1024,
          p: [3, 4],
        }}
      >
        <Styled.h1>{translate('strikeRefundForm.title')}</Styled.h1>
        <p>{translate('strikeRefundForm.text1')}</p>
        <p><b>{translate('strikeRefundForm.text2')}</b> {translate('strikeRefundForm.text3')}</p>
        <StrikeRefundFormik key={locale} {...formProps} />
        {showSpinner && <Spinner size="medium" position="fixed" />}
      </Container>
    </Layout>
  );
};

export default StrikeRefundFormPage;
